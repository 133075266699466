import React, { Component } from 'react'
import _ from 'lodash'
import { batch, connect } from 'react-redux'
import classnames from 'classnames'
import { createSelector } from 'reselect'
import {
  subMonths,
  startOfMonth,
  endOfMonth,
  addMonths,
  endOfYear,
  addYears,
  isBefore,
  differenceInCalendarMonths,
  isAfter,
  lastDayOfMonth,
  subDays,
  isSameDay,
} from 'date-fns'

import { displayedDaysArraySelector, copyPreviousDayFunctionsSelector, hourCodesSelector } from '../lib/selectors'
import { getElementVerticalScrollPosition } from '../lib/scroll'
import {
  calculateDiffForDay,
  generateDisplayedMonths,
  injectTotalHoursAndFlexToMonths,
  isFlexitimeCode,
} from '../lib/util'
import {
  formatISO,
  isFirstDayOfWeekend,
  isLastDayOfWeekend,
  isWeekday,
  otherWeekendDay,
  today,
  tomorrow,
  nextWorkingDay,
  previousWorkingDay,
  formatISOMonth,
  isLastDayOfMonth,
} from '../lib/date'

import LoadMore from './LoadMore'
import Spinner from '../Spinner'
import ViewWorkingDay from './Days/ViewWorkingDay'
import EditWorkingDay from './Days/EditWorkingDay'
import NoConnectionIndicator from '../NoConnectionIndicator'
import ScrollToToday from './ScrollToToday'

import styles from '../css/DayList.module.css'
import { RootState } from '../lib/store'
import {
  CountryID,
  ExpectedDailyHours,
  HourCode,
  HoursContractSeason,
  WorkDay,
  WorkDayValidation,
  WorkEntry,
  WorkMonth,
  EditContext,
  CopyPreviousDayFunctionMapType,
  AbsenceCode,
} from '../lib/domain'
import { Dispatch } from 'redux'
import { UIState } from '../lib/reducers/ui'
import { Locale } from '../lib/reducers/settings'
import { MonthSummary } from './MonthSummary'
import {
  isLastWorkDayOfMonthForEmployee,
  isWorkDayForEmployee,
  isDayInLockedMonth,
  isFullDayAbsence,
} from '../lib/dateUtil'
import { getWorkhoursLowLimit } from '../lib/config'
import Weekend from './Days/Weekend'
import { SUSPICIOUSLY_FEW_HOURS } from '../lib/validation'

const isWeekendDayWithEntryOnWeekend = (daysArray: [string, WorkDay][], dayIndex: number, country?: CountryID) => {
  const days = daysArray[dayIndex]
  if (!days) {
    throw new Error('Days are undefined')
  }

  const day = days[0]

  const dayHasEntry = (i: number) => {
    const dayToUse = daysArray[i]
    if (!dayToUse) {
      return false
    }
    return dayToUse[1].entries.length > 0
  }

  const tomorrowIndex = dayIndex + 1
  const yesterdayIndex = dayIndex - 1
  return (
    (isFirstDayOfWeekend(day, country) && (dayHasEntry(dayIndex) || dayHasEntry(tomorrowIndex))) ||
    (isLastDayOfWeekend(day, country) && (dayHasEntry(dayIndex) || dayHasEntry(yesterdayIndex)))
  )
}

const validateWorkDayForMissingEntries = (day: WorkDay, contract?: HoursContractSeason) => {
  if (day.entries.length === 0) return undefined

  const dayHasAbsentFlexitime = day.entries.some((e) => isFlexitimeCode(e.hourCode))
  if (dayHasAbsentFlexitime) return undefined

  const workHoursLowLimit = getWorkhoursLowLimit(day.day, contract)

  // We can't trust `day.total` here if the entries have not been yet sent to the server.
  // This method takes local changes into account.
  const totalHours = day.entries.reduce((sum, entry) => sum + parseFloat(entry.hours), 0)

  if (totalHours < workHoursLowLimit) {
    return { [SUSPICIOUSLY_FEW_HOURS]: `${day.total} < ${workHoursLowLimit}` } as WorkDayValidation
  }

  return undefined
}

// The class is given to div elements so the type assertion is valid
const getWorkingDayDiv = (day: string) =>
  document.getElementsByClassName(`workingDay-${day}`)[0] as HTMLDivElement | undefined
const getFirstWorkingDayDiv = () => _.first(document.getElementsByClassName('workingDay')) as HTMLDivElement | undefined
const getLastWorkingDayDiv = () => _.last(document.getElementsByClassName('workingDay')) as HTMLDivElement | undefined

// a bit fragile :/
const getAssumedKeyboardNavigationEntryOnDayChange = (
  day: string,
  daysArray: [string, WorkDay][],
  startEndShown: boolean
) => {
  const column = startEndShown ? 'startTime' : 'hours'
  const dayData = daysArray.find(([day_]) => day === day_)
  if (!dayData) {
    return { index: 0, column }
  }

  return { index: dayData[1].entries.length, column }
}

type DayListRequiredProps = {
  modifyEntry: (editContext: EditContext, updateDiff: Partial<WorkEntry>) => void
  onScroll: (e: React.UIEvent) => void
  isLockingEnabled: boolean
  serverDays: Record<string, WorkDay>
  serverMonths: Record<WorkMonth['month'], Pick<WorkMonth, 'month' | 'locked' | 'lockRequired'>>
}

type DayListProps = DayListRequiredProps & {
  daysArray: [string, WorkDay][]
  publicHolidays: Record<string, string>
  locale: Locale
  startEndShown: boolean
  userExpectedHours: Record<string, ExpectedDailyHours>
  rescrollNonce?: number
  dispatch: Dispatch
  copyPreviousDayFunctionMap: CopyPreviousDayFunctionMapType
  hourCodes: HourCode[]
  showLoadPreviousMonthsButton: boolean
  showLoadNextMonthsButton: boolean
  numMonthsBack: number
  numMonthsForward: number
  currentlyEditingDay: string
  isServerConnectionWorking: boolean
  contracts: HoursContractSeason[]
  ui: UIState
  daysWithErrors: Date[]
  dataOwnerUserName: string
  monthLockFeatureEnabled: boolean
  absenceCodes: AbsenceCode[]
}

type DayListSnapshot = {
  documentTopOffset: number
  viewportTopOffset: number
} | null

type DayListState = {
  openedWeekendDays: string[]
}

type EnhancedWorkDay = WorkDay & {
  locked: boolean
  contract?: HoursContractSeason
  month: string
  serverMonth?: {
    month: string
    locked: boolean
    lockRequired: boolean
  }
  holiday?: string
  isLastWorkDayOfMonth: boolean
  isWorkDay: boolean
  missingWorkingHours: number
  daysWithErrorsInMonth: Date[]
  possiblyIncompleteFutureDays: string[]
}

class DayList extends Component<DayListProps, DayListState> {
  private entryFocusFns: Record<number, () => void> = {}
  private readonly scrollContainerRef: React.RefObject<HTMLDivElement> = React.createRef()
  private scrollToDayAfterMonthsChanged?: string

  state: DayListState = {
    openedWeekendDays: [],
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown)
  }

  getSnapshotBeforeUpdate(prevProps: DayListProps) {
    if (
      this.props.rescrollNonce !== prevProps.rescrollNonce ||
      prevProps.currentlyEditingDay !== this.props.currentlyEditingDay
    ) {
      return getElementVerticalScrollPosition(getWorkingDayDiv(this.props.currentlyEditingDay))
    }

    return null
  }

  componentDidUpdate(prevProps: DayListProps, prevState: {}, snapshot: DayListSnapshot) {
    if (this.props.rescrollNonce !== prevProps.rescrollNonce) {
      this.scrollToDay(this.props.currentlyEditingDay)
      this.focusCurrentlyEditingEntry(this.props.ui.keyboardNavigation.entry)
    } else if (
      prevProps.currentlyEditingDay !== this.props.currentlyEditingDay &&
      snapshot &&
      this.scrollContainerRef.current
    ) {
      const workingDayDiv = getWorkingDayDiv(this.props.currentlyEditingDay)
      if (workingDayDiv) {
        // prevents "scroll jumping" when a day above closes
        const { viewportTopOffset: prevViewportTopOffset } = snapshot
        const { viewportTopOffset } = getElementVerticalScrollPosition(workingDayDiv)
        const offsetDelta = viewportTopOffset - prevViewportTopOffset

        this.scrollContainerRef.current.scrollTop = this.scrollContainerRef.current.scrollTop + offsetDelta
      }

      // must only be called if currently edited day changes, otherwise focus is robbed
      // by last focused input when clicking anywhere on EditModeEntry
      this.focusCurrentlyEditingEntry(this.props.ui.keyboardNavigation.entry)
    }

    if (
      this.props.numMonthsBack !== prevProps.numMonthsBack ||
      this.props.numMonthsForward !== prevProps.numMonthsForward
    ) {
      if (this.scrollToDayAfterMonthsChanged) {
        this._scrollToDay(this.scrollToDayAfterMonthsChanged)
        this.scrollToDayAfterMonthsChanged = undefined
        return
      }
    }
    if (this.props.numMonthsBack !== prevProps.numMonthsBack) {
      this.scrollToElem(getFirstWorkingDayDiv())
    }
    if (this.props.numMonthsForward !== prevProps.numMonthsForward) {
      this.scrollToElem(getLastWorkingDayDiv())
    }
  }

  focusCurrentlyEditingEntry(keyboardNavigationEntry: { index: number }) {
    const focusfn = this.entryFocusFns[keyboardNavigationEntry.index]
    if (focusfn) {
      focusfn()
    }
  }

  // Note: cannot scroll to weekend
  scrollToDay = (day: string) => {
    const { numMonthsBack, numMonthsForward, currentlyEditingDay, daysArray, startEndShown } = this.props
    const firstDisplayedDay = startOfMonth(subMonths(today(), numMonthsBack))
    const lastDisplayedDay = endOfMonth(addMonths(today(), numMonthsForward))

    // check if more days have to be rendered before scrolling
    if (isBefore(day, firstDisplayedDay)) {
      this.scrollToDayAfterMonthsChanged = day
      this.props.dispatch({ type: 'SHOW_MORE_MONTHS_BACK', amount: differenceInCalendarMonths(firstDisplayedDay, day) })
    } else if (isAfter(day, lastDisplayedDay)) {
      this.scrollToDayAfterMonthsChanged = day
      this.props.dispatch({
        type: 'SHOW_MORE_MONTHS_FORWARD',
        amount: differenceInCalendarMonths(day, lastDisplayedDay),
      })
    } else {
      const navEntry = getAssumedKeyboardNavigationEntryOnDayChange(currentlyEditingDay, daysArray, startEndShown)
      this.focusCurrentlyEditingEntry(navEntry)
      this._scrollToDay(day)
    }
  }

  _scrollToDay = (isoDay: string) => {
    this.scrollToElem(getWorkingDayDiv(isoDay))
  }

  scrollToElem = (elem?: HTMLElement) => {
    if (elem) {
      // Safari doesn't yet implement additional options to
      // `scrollIntoView`. Once it does, we can remove this hack.
      if (navigator.vendor.startsWith('Apple') || navigator.userAgent.indexOf('CriOS') !== -1) {
        if (this.scrollContainerRef.current) {
          const offset = elem.offsetTop - window.innerHeight / 2 + elem.offsetHeight / 2
          this.scrollContainerRef.current.scrollTop = offset
        }
      } else {
        elem.scrollIntoView({ block: 'center' })
      }
    }
  }

  loadOneMonthBack = () => {
    this.props.dispatch({ type: 'SHOW_MORE_MONTHS_BACK', amount: 1 })
  }

  loadOneMonthForward = () => {
    this.props.dispatch({ type: 'SHOW_MORE_MONTHS_FORWARD', amount: 1 })
  }

  isLockEnabledAndDayIsInLockedMonth = (day: string) =>
    this.props.monthLockFeatureEnabled ? isDayInLockedMonth(day, this.props.serverMonths) : false

  hasUpcomingAbsenceBeforeEndOfMonth = (enhancedDaysArray: EnhancedWorkDay[]): boolean => {
    const todayDate = new Date()
    let hasAbsencesAfter = false
    let workdaysBetweenTodayAndAbsence = 0

    // Go through days from the end of the current month backwards until the current date
    for (let day = lastDayOfMonth(todayDate); !isSameDay(day, todayDate); day = subDays(day, 1)) {
      const dayData = enhancedDaysArray.find((d) => d.day === formatISO(day))
      if (!dayData) break

      // Only count working days
      if (!dayData.isWorkDay) continue

      const isAbsence = isFullDayAbsence(dayData, this.props.absenceCodes, this.props.userExpectedHours)

      if (isAbsence) {
        hasAbsencesAfter = true
      } else {
        if (hasAbsencesAfter) {
          workdaysBetweenTodayAndAbsence++
        } else {
          return false
        }
      }
    }

    return hasAbsencesAfter && workdaysBetweenTodayAndAbsence <= 2
  }

  onDayClick = (day: string) => {
    if (this.isLockEnabledAndDayIsInLockedMonth(day)) {
      return
    }

    // reset entryFocusFns because they are registered when Entry is rendered.
    this.entryFocusFns = {}
    const { dispatch, daysArray, startEndShown } = this.props
    batch(() => {
      dispatch({ type: 'CHANGE_CURRENTLY_EDITING_DAY', day })

      // optimization - prevents rerendering twice when for example day is clicked.
      // (Before, DayList was rendered first when the 'currently editing day change' action was dispatched
      // and then second time when the newly rendered Entry gained focus and dispatched a 'ui keyboard navigation' action)
      const keyboardNavigationEntry = getAssumedKeyboardNavigationEntryOnDayChange(day, daysArray, startEndShown)
      dispatch({ type: 'UI_KEYBOARD_NAVIGATION', keyboardNavigation: { entry: keyboardNavigationEntry } })
    })
  }

  onDayItemClick = (day: string, index: number, name: string) => {
    // reset entryFocusFns because they are registered when Entry is rendered.
    this.entryFocusFns = {}
    const { dispatch } = this.props
    batch(() => {
      dispatch({ type: 'CHANGE_CURRENTLY_EDITING_DAY', day })
      dispatch({ type: 'UI_KEYBOARD_NAVIGATION', keyboardNavigation: { entry: { index, column: name } } })
    })
  }

  onKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        this.stopEditing()
        break
      case 'Enter':
      case 'ArrowUp':
      case 'ArrowDown':
        this.navigateKeyboardVertical(e)
        break
      default:
    }
  }

  selectCurrentDay = () => {
    const { dispatch, daysArray, startEndShown } = this.props
    batch(() => {
      dispatch({ type: 'CHANGE_CURRENTLY_EDITING_DAY', day: today() })

      // optimization - prevents rerendering twice when for example day is clicked.
      // (Before, DayList was rendered first when the 'currently editing day change' action was dispatched
      // and then second time when the newly rendered Entry gained focus and dispatched a 'ui keyboard navigation' action)
      const keyboardNavigationEntry = getAssumedKeyboardNavigationEntryOnDayChange(today(), daysArray, startEndShown)
      dispatch({ type: 'UI_KEYBOARD_NAVIGATION', keyboardNavigation: { entry: keyboardNavigationEntry } })
    })
  }

  stopEditing = () => {
    this.props.dispatch({ type: 'CHANGE_CURRENTLY_EDITING_DAY', day: null })
  }

  navigateKeyboardVertical = (e: KeyboardEvent) => {
    if (this.props.ui.keyboardNavigation.entry.column === 'hourCode' && e.key !== 'Enter') return
    const day = this.props.currentlyEditingDay
    const entryFocusFns = this.entryFocusFns
    const entryCount = entryFocusFns ? Object.keys(entryFocusFns).length : 0
    const entryIndex = this.props.ui.keyboardNavigation.entry.index
    const loadAndClickDay = (dayToClick: string, loadfn: () => void) => {
      if (this.isLockEnabledAndDayIsInLockedMonth(dayToClick)) {
        return
      }

      if (!this.props.daysArray.find(([d]) => d === dayToClick)) loadfn()
      this.onDayClick(dayToClick)
    }

    switch (e.key) {
      case 'Enter':
      case 'ArrowDown': {
        if (entryCount <= 1 || entryIndex + 1 === entryCount) {
          loadAndClickDay(nextWorkingDay(day, this.props.publicHolidays), this.loadOneMonthForward)
        } else if (entryFocusFns[entryIndex + 1]) {
          entryFocusFns[entryIndex + 1]?.()
        }
        break
      }
      case 'ArrowUp': {
        if (entryIndex === 0) {
          loadAndClickDay(previousWorkingDay(day, this.props.publicHolidays), this.loadOneMonthBack)
        } else {
          entryFocusFns[entryIndex - 1]?.()
        }
        break
      }
      default:
    }
  }

  // Entry calls this sequentially for every entry per day
  registerFocus = (entryIndex: number, focusFn: () => void) => {
    this.entryFocusFns = { ...this.entryFocusFns, [entryIndex]: focusFn }
  }

  render() {
    const {
      daysArray,
      copyPreviousDayFunctionMap,
      contracts,
      userExpectedHours,
      publicHolidays,
      hourCodes,
      modifyEntry,
      locale,
      startEndShown,
      currentlyEditingDay,
      isServerConnectionWorking,
      showLoadPreviousMonthsButton,
      showLoadNextMonthsButton,
      onScroll,
      isLockingEnabled,
      serverDays,
      serverMonths,
      ui,
      daysWithErrors,
      dataOwnerUserName,
      absenceCodes,
    } = this.props
    const { openedWeekendDays } = this.state
    const { onDayClick, onDayItemClick, selectCurrentDay, scrollContainerRef, scrollToDay } = this

    if (daysArray.length === 0) {
      return (
        <div id='daylist'>
          <NoConnectionIndicator locale={locale} hasConnection={isServerConnectionWorking} />
          <Spinner />
        </div>
      )
    }

    const todayISO = today()
    const possiblyIncompleteFutureDaysByMonth: Record<string, string[]> = {}

    const enhancedDaysArray: EnhancedWorkDay[] = daysArray.map(([, dayData]) => {
      const { day } = dayData

      const locked = this.isLockEnabledAndDayIsInLockedMonth(day)
      const contract = contracts.find((c) => c.start <= day && (!c.finish || c.finish >= day))
      const month = formatISOMonth(day)
      const serverMonth = serverMonths[month]
      const holiday = publicHolidays[day]
      const isWorkDay = isWorkDayForEmployee(day, contract, publicHolidays, userExpectedHours[day])
      const missingWorkingHours = -1 * calculateDiffForDay(dayData, userExpectedHours[day])
      const isLastWorkDayOfMonth = isLastWorkDayOfMonthForEmployee(day, contracts, publicHolidays, userExpectedHours)
      const daysWithErrorsInMonth = daysWithErrors.filter((d) => formatISOMonth(d) === month)

      possiblyIncompleteFutureDaysByMonth[month] = possiblyIncompleteFutureDaysByMonth[month] ?? []
      let futureValidationErrors: WorkDayValidation | undefined = {}

      if (
        isLockingEnabled &&
        day >= todayISO &&
        contract &&
        contract.type !== 'subcontractor' &&
        isWorkDay &&
        !holiday
      ) {
        const validation = validateWorkDayForMissingEntries(dayData, contract)
        if (validation || dayData.entries.length === 0) {
          possiblyIncompleteFutureDaysByMonth[month]?.push(day)
          futureValidationErrors = validation
        }
      }
      const possiblyIncompleteFutureDays = possiblyIncompleteFutureDaysByMonth[month] ?? []

      const enhancedDay: EnhancedWorkDay = {
        ...dayData,
        validation: { ...dayData.validation, ...futureValidationErrors },
        locked,
        contract,
        month,
        isLastWorkDayOfMonth,
        serverMonth,
        holiday,
        isWorkDay,
        missingWorkingHours,
        daysWithErrorsInMonth,
        possiblyIncompleteFutureDays,
      }

      return enhancedDay
    })

    const currentMonth = formatISOMonth(todayISO)

    const firstUnlockedMonthSummaryDay = enhancedDaysArray.find(
      (dayData) => !dayData.locked && dayData.isLastWorkDayOfMonth && dayData.day < currentMonth
    )

    const hasUpcomingAbsenceBeforeEndOfMonth = this.hasUpcomingAbsenceBeforeEndOfMonth(enhancedDaysArray)

    return (
      <div id='daylist'>
        <NoConnectionIndicator locale={locale} hasConnection={isServerConnectionWorking} />
        <div
          data-test='dayList-scrollContainer'
          className={styles.scrollContainer}
          ref={this.scrollContainerRef}
          onScroll={(e) => onScroll(e)}
        >
          {showLoadPreviousMonthsButton && <LoadMore onClick={this.loadOneMonthBack} locale={locale} />}

          {enhancedDaysArray.map((dayData, index) => {
            const {
              day,
              locked,
              contract,
              holiday,
              isWorkDay,
              missingWorkingHours,
              isLastWorkDayOfMonth,
              serverMonth,
              daysWithErrorsInMonth,
              possiblyIncompleteFutureDays,
              month,
            } = dayData

            const isEditableDay = !locked && currentlyEditingDay === day
            const country = contract?.company.country

            const otherWeekendDayOfTodayISO = formatISO(otherWeekendDay(todayISO, country))
            if (
              isWeekday(day, country) ||
              isWeekendDayWithEntryOnWeekend(daysArray, index, country) ||
              openedWeekendDays.includes(day) ||
              day === todayISO ||
              day === otherWeekendDayOfTodayISO
            ) {
              const copyPreviousDayFn = copyPreviousDayFunctionMap[day]
              const copyPreviousDay =
                copyPreviousDayFn && (() => copyPreviousDayFn(modifyEntry).then(() => this.onDayClick(day))) // yikes

              const monthRequiresLocking = serverMonth?.lockRequired
              const monthIsLocked = serverMonth?.locked ?? false
              const displaySummary = isLastWorkDayOfMonth && isLockingEnabled && (monthRequiresLocking || monthIsLocked)
              const displayFlexitime = contract?.type !== 'not_required' && contract?.type !== 'subcontractor'

              let flexitimeDelta = 0

              if (displaySummary) {
                const displayMonth = generateDisplayedMonths(
                  formatISO(startOfMonth(day)),
                  formatISO(lastDayOfMonth(day))
                )
                const data = injectTotalHoursAndFlexToMonths(displayMonth, serverDays, userExpectedHours)
                flexitimeDelta = data[0]?.flex ?? 0
              }

              return (
                <React.Fragment key={day}>
                  <div className={classnames(styles.day, styles.item)} onClick={() => onDayClick(day)}>
                    {isEditableDay ? (
                      <EditWorkingDay
                        {...{
                          registerFocus: this.registerFocus,
                          dayData,
                          startEndShown,
                          holiday,
                          missingWorkingHours,
                          hourCodes,
                          locale,
                          modifyEntry,
                          ui,
                          isWorkDay,
                          copyPreviousDay,
                        }}
                      />
                    ) : (
                      <ViewWorkingDay
                        {...{
                          dayData,
                          startEndShown,
                          holiday,
                          missingWorkingHours,
                          locale,
                          isWorkDay,
                          copyPreviousDay,
                          onDayItemClick: (i: number, n: string) => onDayItemClick(day, i, n),
                          locked,
                        }}
                      />
                    )}
                  </div>
                  {displaySummary && (
                    <MonthSummary
                      dateISO={day}
                      serverDays={serverDays}
                      flexitimeDelta={flexitimeDelta}
                      displayFlexitime={displayFlexitime}
                      locale={locale}
                      isLocked={monthIsLocked}
                      isPastDue={firstUnlockedMonthSummaryDay?.day === day}
                      hasUpcomingAbsence={currentMonth === month && hasUpcomingAbsenceBeforeEndOfMonth}
                      monthHasErrors={daysWithErrorsInMonth.length > 0 || possiblyIncompleteFutureDays.length > 0}
                      dataOwnerUserName={dataOwnerUserName}
                      absenceCodes={absenceCodes}
                    />
                  )}
                </React.Fragment>
              )
            } else if (isLastDayOfWeekend(day, country)) {
              // last day of weekend is rendered above
              return null
            } else {
              const weekendLocked = isLastDayOfMonth(day)
                ? locked && isDayInLockedMonth(formatISO(tomorrow(day)), this.props.serverMonths)
                : locked
              return (
                <Weekend
                  key={day}
                  {...{
                    day,
                    locked: weekendLocked,
                    locale,
                    country,
                  }}
                  onClick={() => {
                    if (!weekendLocked) {
                      this.setState({
                        openedWeekendDays: [day, formatISO(tomorrow(day))],
                      })
                    }
                  }}
                />
              )
            }
          })}
          {showLoadNextMonthsButton && <LoadMore onClick={this.loadOneMonthForward} locale={locale} />}
        </div>
        <ScrollToToday {...{ locale, scrollContainerRef, scrollToDay, selectCurrentDay }} />
      </div>
    )
  }
}

export type DayListCompType = DayList

const showLoadPreviousMonthsButtonSelector = createSelector(
  (state: RootState) => state.user.dataOwnerUser.startDate,
  (state: RootState) => state.settings.numMonthsBack,
  (startDate, numMonthsBack) => startOfMonth(subMonths(today(), numMonthsBack)) > new Date(startDate)
)

const showLoadNextMonthsButtonSelector = createSelector(
  (state: RootState) => state.settings.numMonthsForward,
  (numMonthsForward) => endOfMonth(addMonths(today(), numMonthsForward)) < endOfYear(addYears(today(), 1))
)

const mapStateToProps = (state: RootState, props: DayListRequiredProps) => {
  const {
    contracts,
    publicHolidays,
    currentlyEditingDay,
    isServerConnectionWorking,
    userExpectedHours,
    rescrollNonce,
  } = state.data.personalHoursClient
  const { locale, startEndShown, numMonthsBack, numMonthsForward, monthLockFeatureEnabled } = state.settings

  return {
    ...props,
    daysArray: displayedDaysArraySelector(state),
    copyPreviousDayFunctionMap: copyPreviousDayFunctionsSelector(state),
    hourCodes: hourCodesSelector(state),
    showLoadPreviousMonthsButton: showLoadPreviousMonthsButtonSelector(state),
    showLoadNextMonthsButton: showLoadNextMonthsButtonSelector(state),

    numMonthsBack,
    numMonthsForward,
    currentlyEditingDay,
    rescrollNonce,
    isServerConnectionWorking,
    contracts,
    publicHolidays,
    locale,
    startEndShown,
    ui: state.ui,
    userExpectedHours,
    monthLockFeatureEnabled,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(DayList)
