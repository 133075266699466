// date.js proved difficult to convert to TypeScript, so this is a new file for TS date utilities
import { lastDayOfMonth, subDays } from 'date-fns'
import { AbsenceCode, EmploymentContract, ExpectedDailyHours, HoursContractSeason, WorkDay, WorkMonth } from './domain'
import { formatISO, formatISOMonth, getISODayOfWeek, isWeekday, parseISODate, isAfter, isBefore } from './date'
import { minBy } from 'lodash'
import { isFlexitimeCode } from './util'
import _ from 'lodash'

export const isWorkDayForEmployee = (
  day: string,
  contract: HoursContractSeason | undefined,
  publicHolidays: Record<string, string>,
  userExpectedHoursForDay?: ExpectedDailyHours
) => {
  if (!contract || contract.type === 'away') {
    return false
  }

  if (publicHolidays[formatISO(day)]) {
    return false
  }

  const { country } = contract.company
  const expectedHours = (userExpectedHoursForDay && userExpectedHoursForDay.hours) || 0
  const isNonWorkDayBasedOnContract = contract.nonWorkdays.includes(getISODayOfWeek(day))
  if (isNonWorkDayBasedOnContract && expectedHours === 0) {
    return false
  }

  return isWeekday(day, country)
}

export const isLastWorkDayOfMonthForEmployee = (
  day: string,
  contracts: HoursContractSeason[],
  publicHolidays: Record<string, string>,
  userExpectedHours: Record<string, ExpectedDailyHours>
) => {
  let date = lastDayOfMonth(parseISODate(day))
  const monthOfDate = date.getMonth()

  while (date.getMonth() === monthOfDate) {
    const isoDate = formatISO(date)
    const contractSeason = contracts.find((c) => c.start <= isoDate && (!c.finish || c.finish >= isoDate))
    if (isWorkDayForEmployee(isoDate, contractSeason, publicHolidays, userExpectedHours[isoDate])) {
      return isoDate === day
    }
    date = subDays(date, 1)
  }

  return false
}

export const getLastWorkDayOfMonthForEmployee = (
  dateInMonth: string,
  contract: HoursContractSeason | undefined,
  publicHolidays: Record<string, string>,
  userExpectedHours: Record<string, ExpectedDailyHours>
) => {
  const initialDate = lastDayOfMonth(dateInMonth)
  let date = initialDate

  while (!isWorkDayForEmployee(formatISO(date), contract, publicHolidays, userExpectedHours[formatISO(date)])) {
    date = subDays(date, 1)

    if (date.getMonth() !== initialDate.getMonth()) {
      throw new Error('Could not find last work day of month')
    }
  }

  return formatISO(date)
}

export const getEarliestUnlockedMonth = (
  lastMonth: string,
  months: Record<string, Pick<WorkMonth, 'month' | 'locked' | 'lockRequired'>>
) => {
  const monthStarts = Object.values(months)
    .filter(({ locked, lockRequired }) => lockRequired && !locked)
    .filter(({ month }) => month < lastMonth)
    .map(({ month }) => month)

  if (monthStarts.length === 0) return undefined

  return minBy(monthStarts, (m) => parseISODate(m))
}

export const isDayInLockedMonth = (day: string, months: Record<string, Pick<WorkMonth, 'month' | 'locked'>>) => {
  const monthOfDate = formatISOMonth(parseISODate(day))
  return months[monthOfDate]?.locked ?? false
}

export const isFullDayAbsence = (
  workDay: WorkDay,
  absenceCodes: AbsenceCode[],
  expectedHours: Record<string, ExpectedDailyHours>
): boolean => {
  const dayHasEntries = workDay.entries.length > 0
  const isFlexitimeAbsence = dayHasEntries && workDay.entries.every((entry) => isFlexitimeCode(entry.hourCode))

  if (isFlexitimeAbsence) {
    return true
  }

  return workDay.entries.some(
    (entry) =>
      absenceCodes.find(({ code }) => code === entry.hourCode.toLowerCase()) &&
      parseFloat(entry.hours) === expectedHours[workDay.day]?.hours
  )
}

export const seasonOnDay = ({ seasons }: EmploymentContract, day: string) =>
  _.reverse([...seasons]).find((season) => isBefore(season.start, day))

const isContractActive = (contract: EmploymentContract, day: string) =>
  isBefore(contract.seasons[0]?.start, day) && (!contract.end || isAfter(contract.end, day))

export const contractOnDay = (contracts: EmploymentContract[], day: string) =>
  contracts.find((c) => isContractActive(c, day))
