import {
  CountryID,
  EmployeeBaseDifferenceAdjustment,
  EmployeeBaseDifferenceAdjustments,
  EmploymentContracts,
  ReportingResponse,
  UnlockedHoursResponse,
  UnpaidLeaveCheckpoints,
  UsernameReportWorkEntry,
  UsersWithUnlockedHours,
  UserUnlockedHoursByCountry,
} from '../domain'
import { fetchCurrentEmployeeContractsWithVacations } from '../hoursApi'
import { AppDispatch } from '../store'
import { PayloadAction } from './payload-action'

type EmployeesByCountry = { [locale: string]: EmploymentContracts[] }

type UpdateCountryEmployeesAction = PayloadAction<
  'UPDATE_COUNTRY_EMPLOYEES',
  { employeesByCountry: EmployeesByCountry }
>

type UpdateUsersWithUnlockedHoursAction = PayloadAction<
  'UPDATE_USERS_WITH_UNLOCKED_HOURS',
  { usersWithUnlockedHours: UsersWithUnlockedHours }
>

type UserUnlockedHoursByCountryAction = PayloadAction<
  'UPDATE_USER_UNLOCKED_MONTHS_BY_COUNTRY',
  { userUnlockedHoursOfCountry: UnlockedHoursResponse; country: CountryID }
>

type UpdateUnpaidLeaveCheckpointsAction = PayloadAction<
  'UPDATE_UNPAID_LEAVE_CHECKPOINTS',
  { username: string; checkpoints: UnpaidLeaveCheckpoints }
>

type UpdateWorkEntriesAction = PayloadAction<
  'UPDATE_REPORT_WORKENTRIES',
  { reportWorkEntriesResponse: ReportingResponse<UsernameReportWorkEntry> }
>

type UpdateCountryBaseDifferenceAdjustmentsAction = PayloadAction<
  'UPDATE_COUNTRY_BASE_DIFFERENCE_ADJUSTMENTS',
  { countryBaseDifferenceAdjustments: Record<string, EmployeeBaseDifferenceAdjustments[]> }
>

type UserCurrentFlexihours = PayloadAction<
  'UPDATE_USER_CURRENT_FLEXIHOURS',
  { currentFlexihours: Record<string, number> }
>

type AddCountryBaseDifferenceAdjustmentAction = PayloadAction<
  'ADD_COUNTRY_BASE_DIFFERENCE_ADJUSTMENT',
  { country: CountryID; adjustment: EmployeeBaseDifferenceAdjustment }
>

type AdminState = {
  employeesByCountry: EmployeesByCountry
  unpaidLeaveCheckpointsPerEmployee: { [username: string]: UnpaidLeaveCheckpoints }
  reportWorkEntriesResponse: ReportingResponse<UsernameReportWorkEntry>
  usersWithUnlockedHours: UsersWithUnlockedHours
  userUnlockedHoursByCountry: UserUnlockedHoursByCountry
  countryBaseDifferenceAdjustments: Record<string, EmployeeBaseDifferenceAdjustments[]>
  currentFlexihours: Record<string, number>
}

type AdminStateAction =
  | UpdateCountryEmployeesAction
  | UpdateUnpaidLeaveCheckpointsAction
  | UpdateWorkEntriesAction
  | UpdateUsersWithUnlockedHoursAction
  | UserUnlockedHoursByCountryAction
  | UpdateCountryBaseDifferenceAdjustmentsAction
  | UserCurrentFlexihours
  | AddCountryBaseDifferenceAdjustmentAction

export const EMPTY_RESPONSE = { results: [], resultsLimit: -1 }

const DEFAULT_ADMIN_STATE: AdminState = {
  employeesByCountry: {},
  unpaidLeaveCheckpointsPerEmployee: {},
  reportWorkEntriesResponse: EMPTY_RESPONSE,
  usersWithUnlockedHours: [],
  userUnlockedHoursByCountry: [],
  countryBaseDifferenceAdjustments: {},
  currentFlexihours: {},
}

const appendAdjustment = (
  countryAdjustments: EmployeeBaseDifferenceAdjustments[],
  adjustment: EmployeeBaseDifferenceAdjustment
) => {
  const hasPreviousAdjustments = countryAdjustments.some(
    (employeeAdjustments) => employeeAdjustments.username === adjustment.username
  )

  return hasPreviousAdjustments
    ? countryAdjustments.map((employeeAdjustments) =>
        employeeAdjustments.username === adjustment.username
          ? { ...employeeAdjustments, adjustments: [...employeeAdjustments.adjustments, adjustment] }
          : employeeAdjustments
      )
    : [...countryAdjustments, { username: adjustment.username, adjustments: [adjustment] }]
}

export const adminClient = (state: AdminState = DEFAULT_ADMIN_STATE, action: AdminStateAction): AdminState => {
  switch (action.type) {
    case 'UPDATE_COUNTRY_EMPLOYEES': {
      return {
        ...state,
        employeesByCountry: {
          ...state.employeesByCountry,
          ...action.employeesByCountry,
        },
      }
    }
    case 'UPDATE_UNPAID_LEAVE_CHECKPOINTS': {
      return {
        ...state,
        unpaidLeaveCheckpointsPerEmployee: {
          ...state.unpaidLeaveCheckpointsPerEmployee,
          [action.username]: action.checkpoints,
        },
      }
    }
    case 'UPDATE_USERS_WITH_UNLOCKED_HOURS': {
      return { ...state, usersWithUnlockedHours: action.usersWithUnlockedHours }
    }
    case 'UPDATE_USER_UNLOCKED_MONTHS_BY_COUNTRY': {
      const userUnlockedHours: Map<string, string[]> = new Map()

      Object.entries(action.userUnlockedHoursOfCountry).forEach(([month, countryData]) => {
        Object.entries(countryData).forEach(([, users]) => {
          users.forEach((user) => {
            const userMonths = userUnlockedHours.get(user.username) ?? []
            userMonths.push(month)
            userUnlockedHours.set(user.username, userMonths)
          })
        })
      })

      const userUnlockedHoursOfCountry: UserUnlockedHoursByCountry[number] = {
        country: action.country,
        userUnlockedHours: Object.fromEntries(
          Array.from(userUnlockedHours.entries()).map(([username, months]) => [username, months.sort()])
        ),
      }

      return {
        ...state,
        userUnlockedHoursByCountry: [
          ...state.userUnlockedHoursByCountry.filter(
            (countryData) => countryData.country !== userUnlockedHoursOfCountry.country
          ),
          userUnlockedHoursOfCountry,
        ],
      }
    }
    case 'UPDATE_REPORT_WORKENTRIES': {
      return {
        ...state,
        reportWorkEntriesResponse: action.reportWorkEntriesResponse,
      }
    }
    case 'UPDATE_COUNTRY_BASE_DIFFERENCE_ADJUSTMENTS': {
      return {
        ...state,
        countryBaseDifferenceAdjustments: {
          ...state.countryBaseDifferenceAdjustments,
          ...action.countryBaseDifferenceAdjustments,
        },
      }
    }
    case 'UPDATE_USER_CURRENT_FLEXIHOURS': {
      return {
        ...state,
        currentFlexihours: {
          ...state.currentFlexihours,
          ...action.currentFlexihours,
        },
      }
    }
    case 'ADD_COUNTRY_BASE_DIFFERENCE_ADJUSTMENT': {
      const prevCountryAdjustments = state.countryBaseDifferenceAdjustments[action.country] ?? []
      const newCountryAdjustments = appendAdjustment(prevCountryAdjustments, action.adjustment)

      return {
        ...state,
        countryBaseDifferenceAdjustments: {
          ...state.countryBaseDifferenceAdjustments,
          [action.country]: newCountryAdjustments,
        },
        currentFlexihours: {
          ...state.currentFlexihours,
          [action.adjustment.username]:
            (state.currentFlexihours[action.adjustment.username] ?? 0) + action.adjustment.hoursAdjustment,
        },
      }
    }

    default:
      return state
  }
}

// Wrappers
export const updateCountryEmployees = async (selectedCountry: CountryID, dispatch: AppDispatch) => {
  try {
    const employees = await fetchCurrentEmployeeContractsWithVacations(selectedCountry)
    dispatch({ type: 'UPDATE_COUNTRY_EMPLOYEES', employeesByCountry: employees })
  } catch (e) {
    console.error('Could not load employee contracts for user', e)
  }
}
