import React from 'react'
import { RootState } from '../lib/store'
import { connect } from 'react-redux'
import { SettingsState } from '../lib/reducers/settings'
import { LoggedInUser } from '../lib/reducers/user'
import AccessForbiddenView from './AccessForbiddenView'
import FlexiHoursManagement from './FlexiHours/FlexiHoursManagement'

type FlexiHoursProps = {
  loggedInUser: RootState['user']['loggedInUser']
  settings: SettingsState
}

export const userHasRights = (loggedInUser: LoggedInUser) => loggedInUser.isAdmin || loggedInUser.isCountryManager

const FlexiHoursView: React.FC<FlexiHoursProps> = ({ loggedInUser, settings }) => {
  const { locale } = settings
  const firstCountry = loggedInUser.managedCountries[0]
  if (!userHasRights(loggedInUser) || !firstCountry) {
    return <AccessForbiddenView locale={locale} />
  } else {
    return <FlexiHoursManagement countries={loggedInUser.managedCountries} defaultCountry={firstCountry} />
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    loggedInUser: state.user.loggedInUser,
    settings: state.settings,
  }
}

export default connect(mapStateToProps)(FlexiHoursView)
