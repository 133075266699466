import { EmployeeBaseDifferenceAdjustment, EmployeeName, NewBaseDifferenceAdjustment } from '../../lib/domain'
import styles from './FlexiHoursEditorTable.module.css'
import React from 'react'
import classNames from 'classnames'
import { formatISO, formatShortMonth, startOfMonth, today, addDays, formatMonth } from '../../lib/date'
import { InputField } from '../../InputField'
import { RootState } from '../../lib/reducers'
import { orderBy } from 'lodash'
import _ from 'lodash'

const getMonthAndYearFormat = (locale: RootState['settings']['locale']) => (dateStr: string) =>
  `${_.capitalize(formatShortMonth(locale)(dateStr))} ${new Date(dateStr).getFullYear()}`

type EmployeeAdjustmentsAndFlexihours = {
  employee: EmployeeName
  unlockedHours: string[]
  adjustments: EmployeeBaseDifferenceAdjustment[]
  currentFlexiHours: number
}

type Props = {
  locale: RootState['settings']['locale']
  employees: EmployeeAdjustmentsAndFlexihours[]
  addAdjustment: (username: string, adjustment: NewBaseDifferenceAdjustment) => Promise<void>
}

type NewAdjustmentData = {
  hoursAdjustment: string
  note: string
}

type EmployeeRowProps = {
  employee: EmployeeAdjustmentsAndFlexihours
  locale: RootState['settings']['locale']
  saveAdjustment: () => void
  isSelected: boolean
  newAdjustment: NewAdjustmentData | null
  toggleSelected: () => void
  setNewAdjustment: (value: NewAdjustmentData | null) => void
}

type EmployeeOrderKey = 'name' | 'uid' | 'currentFlexihours'
type EmployeeOrderBy = {
  key: EmployeeOrderKey
  order: 'asc' | 'desc'
}

const employeeOrderByFuncs: Record<EmployeeOrderKey, (employee: EmployeeAdjustmentsAndFlexihours) => unknown> = {
  name: (employee) => `${employee.employee.firstName} ${employee.employee.lastName}`,
  uid: (employee) => employee.employee.username,
  currentFlexihours: (employee) => (isNaN(employee.currentFlexiHours) ? -Infinity : employee.currentFlexiHours),
}

const FlexiHourInfoRow: React.FC<{ locale: RootState['settings']['locale'] }> = ({ locale }) => (
  <div className={styles.infoRow}>
    <span>{locale.texts.admin.flexiHoursAdjustments.adjustments}</span>
  </div>
)

const FlexiHourHeaderRow: React.FC<{
  locale: RootState['settings']['locale']
  employeeOrderBy: EmployeeOrderBy
  setEmployeeOrderBy: (order: EmployeeOrderBy) => void
}> = ({ locale, employeeOrderBy, setEmployeeOrderBy }) => {
  const selectKey = (key: EmployeeOrderKey) => {
    if (key === employeeOrderBy.key) {
      setEmployeeOrderBy({ key, order: employeeOrderBy.order === 'asc' ? 'desc' : 'asc' })
    } else {
      setEmployeeOrderBy({ key, order: 'asc' })
    }
  }

  const orderIndicator = (key: EmployeeOrderKey) => {
    if (employeeOrderBy.key !== key) {
      return null
    }

    return (
      <span
        className={classNames(styles.chevron, styles.showChevron, {
          [styles.chevronInverted ?? '']: employeeOrderBy.order === 'desc',
        })}
      />
    )
  }

  const sortableHeader = (key: EmployeeOrderKey, title: string) => (
    <div className={styles.sortableHeader} onClick={() => selectKey(key)}>
      <span>{title}</span> {orderIndicator(key)}
    </div>
  )

  return (
    <div className={classNames(styles.employeeRow, styles.headerRow)} data-test='adjustment-header-row'>
      {sortableHeader('name', locale.texts.admin.flexiHoursAdjustments.columns.name)}
      {sortableHeader('uid', locale.texts.admin.flexiHoursAdjustments.columns.uid)}
      {sortableHeader(
        'currentFlexihours',
        `${getMonthAndYearFormat(locale)(today())} ${
          locale.texts.admin.flexiHoursAdjustments.columns.startingBalanceSuffix
        }`
      )}
      <span>{locale.texts.admin.flexiHoursAdjustments.columns.month}</span>
      <span>{locale.texts.admin.flexiHoursAdjustments.columns.adjustmentHours}</span>
      <span>{locale.texts.admin.flexiHoursAdjustments.columns.note}</span>
    </div>
  )
}

type RowType = 'top-row' | 'secondary-row'

const FlexiHourEmployeeRow: React.FC<EmployeeRowProps> = ({
  employee: { employee, adjustments, currentFlexiHours, unlockedHours },
  newAdjustment,
  locale,
  isSelected,
  setNewAdjustment,
  toggleSelected,
  saveAdjustment,
}) => {
  const lastAdjustment = adjustments[adjustments.length - 1] ?? null
  const allButLastAdjustments = adjustments.slice(0, adjustments.length - 1)

  const monthFormat = getMonthAndYearFormat(locale)
  const fullMonthFormat = (dateStr: string) => _.capitalize(formatMonth(locale)(dateStr))
  const monthStartStr = formatISO(startOfMonth(new Date()))

  const newFlexihours = currentFlexiHours + parseFloat(newAdjustment?.hoursAdjustment ?? '0')

  const adjustmentDateFormat = (dateStr: string) => {
    const prevDay = formatISO(addDays(dateStr, -1))
    return `${monthFormat(prevDay)} → ${monthFormat(dateStr)}`
  }

  const unlockedMonthNames = unlockedHours.map(fullMonthFormat)

  const isExpandable = adjustments.length > 1 || !!newAdjustment

  const rowEndElement = (type: RowType) => {
    if (type === 'secondary-row') {
      return null
    }

    if (unlockedHours.length) {
      return (
        <span className={styles.unlockedMonthNames}>
          {locale.texts.admin.flexiHoursAdjustments.unlockedMonths(unlockedMonthNames)}
        </span>
      )
    }

    return (
      <button
        className={styles.textButton}
        onClick={() =>
          setNewAdjustment({
            hoursAdjustment: '0',
            note: '',
          })
        }
      >
        {locale.texts.admin.flexiHoursAdjustments.adjust}
      </button>
    )
  }

  const nameUidAndHours = () => (
    <>
      <div onClick={toggleSelected} className={styles.name}>
        <span
          className={classNames(styles.chevron, {
            [styles.chevronInverted ?? '']: isSelected,
            [styles.showChevron ?? '']: isExpandable,
          })}
        />
        {employee.firstName} {employee.lastName}
      </div>
      <span>{employee.username}</span>
      <span>{isNaN(currentFlexiHours) ? '-' : currentFlexiHours}</span>
    </>
  )

  const adjustmentDayHoursAndNote = ({
    adjustment,
    rowType,
  }: {
    adjustment: EmployeeBaseDifferenceAdjustment | null
    rowType: RowType
  }) => (
    <>
      <span>{adjustment?.day ? adjustmentDateFormat(adjustment.day) : '-'}</span>
      <span>{adjustment?.hoursAdjustment ?? '-'}</span>
      <div className={styles.noteAndAdjustment}>
        <span>{adjustment?.note ?? '-'}</span>
        {rowEndElement(rowType)}
      </div>
    </>
  )

  const unlockedMonthStyle = {
    [styles.unlockedMonthRow ?? '']: unlockedHours.length,
  }

  return (
    <>
      {!!newAdjustment && (
        <div
          className={classNames(styles.employeeRow, styles.selectedRow)}
          data-test={`adjustment-row-${employee.username}-new`}
        >
          {nameUidAndHours()}
          <span>{adjustmentDateFormat(monthStartStr)}</span>
          <div className={styles.hoursAndNewBalance}>
            <InputField
              value={newAdjustment.hoursAdjustment.toString()}
              onChange={(val) =>
                setNewAdjustment({
                  ...newAdjustment,
                  hoursAdjustment: val,
                })
              }
              className={styles.inputField}
              isError={(val) => isNaN(Number(val))}
              errorClassName={styles.inputError}
            />
            <span className={styles.newBalanceValue}>{isNaN(newFlexihours) ? currentFlexiHours : newFlexihours}</span>
            <span className={styles.newBalanceText}>{locale.texts.admin.flexiHoursAdjustments.startingBalance}</span>
          </div>
          <div className={styles.noteAndAdjustment}>
            <InputField
              value={newAdjustment.note}
              onChange={(value) => setNewAdjustment({ ...newAdjustment, note: value })}
              className={styles.inputField}
            />
            <button
              className={styles.filledButton}
              onClick={saveAdjustment}
              disabled={
                newAdjustment.hoursAdjustment.trim().length === 0 ||
                isNaN(Number(newAdjustment.hoursAdjustment)) ||
                Number(newAdjustment.hoursAdjustment) === 0
              }
            >
              {locale.texts.admin.flexiHoursAdjustments.save}
            </button>
            <button
              className={styles.textButton}
              onClick={() => {
                setNewAdjustment(null)
                toggleSelected()
              }}
            >
              {locale.texts.admin.flexiHoursAdjustments.cancel}
            </button>
          </div>
        </div>
      )}
      <div
        className={classNames(styles.employeeRow, {
          ...unlockedMonthStyle,
          [styles.selectedRow ?? '']: isSelected && isExpandable,
        })}
        data-test={`adjustment-row-${employee.username}-recent`}
      >
        {!newAdjustment && nameUidAndHours()}
        {adjustmentDayHoursAndNote({
          adjustment: lastAdjustment,
          rowType: newAdjustment ? 'secondary-row' : 'top-row',
        })}
      </div>
      {isSelected &&
        allButLastAdjustments.map((adjustment) => (
          <div
            className={classNames(styles.employeeRow, styles.selectedRow, unlockedMonthStyle)}
            key={adjustment.id}
            data-test={`adjustment-row-${employee.username}-previous`}
          >
            {adjustmentDayHoursAndNote({ adjustment, rowType: 'secondary-row' })}
          </div>
        ))}
    </>
  )
}

export const FlexiHoursEditorTable: React.FC<Props> = ({ employees, addAdjustment, locale }) => {
  const [selectedUser, setSelectedUser] = React.useState<string | null>(null)
  const [newAdjustment, setNewAdjustment] = React.useState<NewAdjustmentData | null>(null)
  const [employeeOrderBy, setEmployeeOrderBy] = React.useState<EmployeeOrderBy>({
    key: 'name',
    order: 'asc',
  })
  const setUserNewAdjustment = (username: string, value: NewAdjustmentData | null) => {
    setSelectedUser(username)
    setNewAdjustment(value)
  }

  const toggleUserSelected = (username: string) => {
    if (selectedUser === username) {
      setSelectedUser(null)
      return
    }

    if (selectedUser !== username) {
      setNewAdjustment(null)
    }
    setSelectedUser(username)
  }

  const addUserAdjustment = (username: string, adjustment: NewAdjustmentData) => {
    const hoursAdjustment = Number(adjustment.hoursAdjustment)
    if (isNaN(hoursAdjustment) || hoursAdjustment === 0) {
      return
    }

    setNewAdjustment(null)
    addAdjustment(username, {
      hoursAdjustment,
      note: adjustment.note,
    })
      .then(() => {
        setUserNewAdjustment(username, null)
        return
      })
      .catch((e) => {
        console.error('Could not add flexihours adjustment', e)
        setUserNewAdjustment(username, adjustment)
      })
  }

  const sortedEmployees = orderBy(employees, employeeOrderByFuncs[employeeOrderBy.key], [employeeOrderBy.order])

  return (
    <section className={styles.flexiHoursTable} data-test='flexihours-table'>
      <FlexiHourInfoRow locale={locale} />
      <FlexiHourHeaderRow locale={locale} employeeOrderBy={employeeOrderBy} setEmployeeOrderBy={setEmployeeOrderBy} />
      {sortedEmployees.map((employee) => (
        <FlexiHourEmployeeRow
          locale={locale}
          key={employee.employee.username}
          saveAdjustment={() => !!newAdjustment && addUserAdjustment(employee.employee.username, newAdjustment)}
          employee={employee}
          newAdjustment={selectedUser === employee.employee.username ? newAdjustment : null}
          isSelected={selectedUser === employee.employee.username}
          setNewAdjustment={(value) => setUserNewAdjustment(employee.employee.username, value)}
          toggleSelected={() => toggleUserSelected(employee.employee.username)}
        />
      ))}
    </section>
  )
}
