import { EmployeeName, EmploymentContracts, JapanFlexHolidaySummary } from '../../lib/domain'
import React from 'react'
import styles from './JapanFlexitimeHolidaysTable.module.css'
import { useAppSelector } from '../../lib/hooks'
import adminClientStyles from '../../css/AdminClient.module.css'
import classNames from 'classnames'
import { formatDayMonthYear, today } from '../../lib/date'
import { contractOnDay } from '../../lib/dateUtil'

type Props = {
  employees: EmploymentContracts[]
}

type EmployeeFlexiHolidays = {
  employee: EmployeeName
  flexHoliday: JapanFlexHolidaySummary | null
}

const employeeFullName = (employee: EmployeeName) => `${employee.firstName} ${employee.lastName}`

const employeesAndContracts = (employees: EmploymentContracts[]): EmployeeFlexiHolidays[] => {
  const todayStr = today()
  return employees
    .map(({ employee, contracts }) =>
      employee
        ? {
            employee,
            flexHoliday: contractOnDay(contracts, todayStr)?.japanFlexHolidaySummary ?? null,
          }
        : null
    )
    .filter((employeeFlexiHolidays): employeeFlexiHolidays is EmployeeFlexiHolidays => Boolean(employeeFlexiHolidays))
    .sort((a, b) => employeeFullName(a.employee).localeCompare(employeeFullName(b.employee)))
}
export const JapanFlexitimeHolidaysTable: React.FC<Props> = ({ employees }) => {
  const locale = useAppSelector((state) => state.settings.locale)
  const employeeFlexiHolidays = employeesAndContracts(employees)

  return (
    <section className={classNames(adminClientStyles.container, styles.flexitimeHolidays)}>
      <h1>{locale.texts.admin.japanFlexitimeHolidays.title}</h1>
      <table className={styles.flexitimeHolidaysTable} data-test='japan-flexitime-holidays-table'>
        <thead>
          <tr>
            <th>{locale.texts.admin.japanFlexitimeHolidays.name}</th>
            <th>{locale.texts.admin.japanFlexitimeHolidays.date}</th>
            <th>{locale.texts.admin.japanFlexitimeHolidays.days}</th>
            <th>{locale.texts.admin.japanFlexitimeHolidays.used}</th>
            <th>{locale.texts.admin.japanFlexitimeHolidays.planned}</th>
            <th>{locale.texts.admin.japanFlexitimeHolidays.remaining}</th>
          </tr>
        </thead>
        <tbody>
          {employeeFlexiHolidays.map(({ employee, flexHoliday }, index) => (
            <tr key={index} data-test={`employee-row-${employee.username}`}>
              <td className={styles.name}>
                <span>{employeeFullName(employee)}</span>
                <span>{employee.username}</span>
              </td>
              <td>{flexHoliday?.checkpointDay ? formatDayMonthYear(locale)(flexHoliday.checkpointDay) : '-'}</td>
              <td>{flexHoliday?.total ?? '-'}</td>
              <td>{flexHoliday?.usedDays ?? '-'}</td>
              <td>{flexHoliday?.plannedDays ?? '-'}</td>
              <td>{flexHoliday?.availableDays ?? '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  )
}
