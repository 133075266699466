import React, { useEffect } from 'react'
import { LoggedInUser } from '../lib/reducers/user'
import { useAppDispatch, useAppSelector } from '../lib/hooks'
import { updateCountryEmployees } from '../lib/reducers/admin'
import AccessForbiddenView from './AccessForbiddenView'
import Spinner from '../Spinner'
import { JapanFlexitimeHolidaysTable } from './JapanFlexitimeHolidays/JapanFlexitimeHolidaysTable'

export const userHasRightsToJapanFlexitimeHolidays = (loggedInUser: LoggedInUser) =>
  loggedInUser.isAdmin || (loggedInUser.isCountryManager && loggedInUser.managedCountries.some(({ id }) => id === 'JP'))

export const JapanFlexTimeHolidaysView: React.FC = () => {
  const settings = useAppSelector((state) => state.settings)
  const loggedInUser = useAppSelector((state) => state.user.loggedInUser)

  const japanEmployees = useAppSelector((state) => state.data.adminClient.employeesByCountry['JP'])
  const dispatch = useAppDispatch()

  const currentUserHasRights = userHasRightsToJapanFlexitimeHolidays(loggedInUser)

  useEffect(() => {
    if (!japanEmployees && currentUserHasRights) {
      updateCountryEmployees('JP', dispatch).catch((e) => {
        console.error('Could not load Japan employees', e)
      })
    }
  }, [japanEmployees, currentUserHasRights])

  if (!currentUserHasRights) {
    return <AccessForbiddenView locale={settings.locale} />
  }

  if (!japanEmployees) {
    return <Spinner />
  }

  return <JapanFlexitimeHolidaysTable employees={japanEmployees} />
}
