import React, { useEffect } from 'react'
import { AppDispatch, RootState } from '../lib/store'
import { connect } from 'react-redux'
import { SettingsState } from '../lib/reducers/settings'
import { LoggedInUser } from '../lib/reducers/user'
import UsersWithUnlockedHoursTable from './UsersWithUnlockedHoursTable'
import AccessForbiddenView from './AccessForbiddenView'
import { useAppSelector } from '../lib/hooks'
import Spinner from '../Spinner'
import { fetchUsersWithUnlockedHoursPerMonth } from '../lib/hoursApi'
import { UsersWithUnlockedHours } from '../lib/domain'

type UsersWithUnlockedHoursTableProps = {
  loggedInUser: RootState['user']['loggedInUser']
  dispatch: AppDispatch
  settings: SettingsState
}

export const userHasRights = (loggedInUser: LoggedInUser) => loggedInUser.isAdmin || loggedInUser.isCountryStatsUser

const fetchUsersWithUnlockedHours = async (dispatch: AppDispatch) => {
  try {
    const response = await fetchUsersWithUnlockedHoursPerMonth()
    const usersWithUnlockedHours: UsersWithUnlockedHours = Object.entries(response)
      .map(([month, countryData]) => ({
        month,
        countryData,
      }))
      .sort((a, b) => a.month.localeCompare(b.month))
    dispatch({ type: 'UPDATE_USERS_WITH_UNLOCKED_HOURS', usersWithUnlockedHours })
  } catch (e) {
    console.error('Could not get users with unlocked hours.', e)
  }
}

const UsersWithUnlockedHoursStatusView: React.FC<UsersWithUnlockedHoursTableProps> = ({
  loggedInUser,
  settings,
  dispatch,
}) => {
  const { locale } = settings

  const hasRights = userHasRights(loggedInUser)
  const usersWithUnlockedHours = useAppSelector((state) => state.data.adminClient.usersWithUnlockedHours)

  useEffect(() => {
    if (!hasRights) {
      return
    }

    void fetchUsersWithUnlockedHours(dispatch)
  }, [hasRights])

  if (!hasRights) {
    return <AccessForbiddenView locale={locale} />
  } else {
    return typeof usersWithUnlockedHours === 'object' ? (
      <UsersWithUnlockedHoursTable
        countries={loggedInUser.managedCountries}
        usersWithUnlockedHours={usersWithUnlockedHours}
      />
    ) : (
      <Spinner />
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    loggedInUser: state.user.loggedInUser,
    settings: state.settings,
  }
}

export default connect(mapStateToProps)(UsersWithUnlockedHoursStatusView)
