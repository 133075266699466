import React, { Component, RefObject } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import classnames from 'classnames'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import { IS_E2E_TEST, SERVER_SEND_DEBOUNCE_DELAY, environment } from './lib/config'
import { today, nextWorkingDay, formatISOMonth, parseISODate, isWithinInterval } from './lib/date'
import { findDayListFocusedMonthAndScrollProgress } from './lib/scroll'
import { openWebsocket, WebsocketConnection } from './lib/websocket'
import { addAutofillsToUpdateDiff } from './lib/autofills'
import { createDefaultDay } from './lib/util'
import { displayedDaysArraySelector, copyPreviousEntrySelector, hourCodesSelector } from './lib/selectors'
import {
  fetchContracts,
  fetchCurrentHolidays,
  fetchUnpaidHolidaySummary,
  fetchEmployee,
  fetchHoursEntries,
  fetchProjectCodesWithTimeRanges,
  fetchPublicHolidays,
  postEntries,
  fetchExpectedHours,
  fetchFloatingHolidaySummary,
  fetchQuotaLimitedAbsenceSummary,
  fetchPoliciesGroups,
  fetchAllAbsenceCodes,
  fetchEmployeeBaseDifferenceAdjustments,
  fetchJapanFlexHolidaySummary,
} from './lib/hoursApi'

import DayList, { DayListCompType } from './DayList/DayList'
import Menu from './Menu'
import Header from './Header'
import { HolidayTable, FloatingHolidaysTable } from './HolidayTable/HolidayTable'
import BillingRatio from './BillingRatio'
import Absences from './Absences'
import Summary from './Summary'

import './css/reset.css'
import './css/base.css'
import './css/HoursClient.css'
import './css/WorkingDay.css' // Multiple other components depend on WorkingDay styles so let's accept it's a global stylesheet for now
import { JapanFexHolidayTable, UnpaidVacationTable } from './HolidayTable/common'
import { RootState } from './lib/store'
import { UserState } from './lib/reducers/user'
import { PersonalHoursClientState } from './lib/reducers/personal-hours-client'
import { SettingsState } from './lib/reducers/settings'
import { Dispatch } from 'redux'
import { EditContext, InvoiceTaskInfo, WorkDay, WorkEntry, WorkMonth } from './lib/domain'
import { hasValidationErrors } from './lib/validation'
import { getEarliestUnlockedMonth, getLastWorkDayOfMonthForEmployee } from './lib/dateUtil'
import { isSameMonth, subMonths } from 'date-fns'

const shouldSidebarBeVisibleForWindowSize = () => {
  // NOTE: if 1000 is changed, it probably needs to be changed in some other
  // places as well (find all references to "1000" in this project)
  return window.innerWidth >= 1000
}

type AddEntryType = {
  date?: string
  hours?: string
  code?: string
  note?: string
}
type AddEntryWithDate = AddEntryType & { date: string }

type HoursClientProps = {
  user: UserState
  data: PersonalHoursClientState
  settings: SettingsState
  displayedDays: [string, WorkDay][]
  dispatch: Dispatch
  copyPreviousEntry?: {
    previousEntry: WorkEntry
    context: EditContext
    diff: WorkEntry
  }
  addEntry?: AddEntryType
  hourCodes: InvoiceTaskInfo[]
}

type HoursClientState = {
  allowSidebarRender: boolean
}

// FIXME: move to Calendar component when it is converted to TypeScript
type CalendarHandle = {
  scrollToDay: (d: string) => void
  scrollToMonth: (params: { month: string; monthScrollPercentage?: number }) => void
  getScrollContainerRef: () => RefObject<Element>
}

class HoursClient extends Component<HoursClientProps, HoursClientState> {
  private readonly calendarRef: React.RefObject<CalendarHandle>
  private readonly dayListRef: React.RefObject<DayListCompType>
  private websocket: WebsocketConnection | null

  constructor(props: HoursClientProps) {
    super(props)
    this.state = { allowSidebarRender: shouldSidebarBeVisibleForWindowSize() }

    this.modifyEntry = this.modifyEntry.bind(this)
    this.closeMenuOnEscape = this.closeMenuOnEscape.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.keyboardShortcutListener = this.keyboardShortcutListener.bind(this)
    this.onDayListScrolled = this.onDayListScrolled.bind(this)
    this.onWindowResized = this.onWindowResized.bind(this)
    this.onCalendarDayClicked = this.onCalendarDayClicked.bind(this)
    this.onServerDayUpdatesReceived = this.onServerDayUpdatesReceived.bind(this)
    this.onServerMonthUpdateReceived = this.onServerMonthUpdateReceived.bind(this)

    this.calendarRef = React.createRef()
    this.dayListRef = React.createRef()

    this.websocket = null

    // @ts-ignore since debounce return type is off. See https://github.com/lodash/lodash/issues/4700#issuecomment-717271999
    this.sendLocalChangesToServer = _.debounce(this.sendLocalChangesToServer, SERVER_SEND_DEBOUNCE_DELAY)
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResized)
    this.fetchData()
      .then(() => this.fetchCurrentHolidays())
      .then(() => this.fetchEntries())
      .then(() => this.addEntryHook())
      .catch(console.error)
    window.addEventListener('keydown', this.keyboardShortcutListener)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResized)
    window.removeEventListener('keydown', this.keyboardShortcutListener)
  }

  componentDidUpdate(prevProps: HoursClientProps) {
    // needed at least for iOS
    if (this.props.settings.vacationsPopupShown !== prevProps.settings.vacationsPopupShown) {
      if (this.props.settings.vacationsPopupShown) {
        const ref = this.calendarRef.current && this.calendarRef.current.getScrollContainerRef().current
        ref && disableBodyScroll(ref)
      } else {
        clearAllBodyScrollLocks()
      }
    }
  }

  addEntryHook() {
    if (this.props.addEntry?.date && this.dayListRef.current) {
      const entry = {
        ...this.props.addEntry,
        date: this.props.addEntry.date,
      }
      this.scrollAndApplyUrlAddEntry(entry, this.dayListRef.current)
    } else {
      this.scrollToEarliestUnlockedMonthIfExists()
    }
  }

  scrollAndApplyUrlAddEntry(addEntry: AddEntryWithDate, daylist: DayListCompType) {
    const { dispatch } = this.props

    const day = addEntry.date
    dispatch({ type: 'CHANGE_CURRENTLY_EDITING_DAY', day })
    daylist.scrollToDay(day)
    const indexInDay = this.props.data.serverDays[day]?.entries?.length ?? 0
    const editContext = { day, indexInDay }
    const updateDiff = {
      hours: addEntry.hours || '',
      hourCode: addEntry.code || '',
      note: addEntry.note || '',
    }
    this.modifyEntry(editContext, updateDiff)
  }

  scrollToEarliestUnlockedMonthIfExists() {
    const { dispatch } = this.props
    const { serverMonths, contracts, publicHolidays, userExpectedHours } = this.props.data

    const day = today()

    const contract = contracts.find((c) => c.start <= day && (!c.finish || c.finish >= day))

    if (contract && this.props.settings.monthLockFeatureEnabled) {
      const thisMonthIso = formatISOMonth(day)
      const earliestUnlockedMonthEnd = getEarliestUnlockedMonth(thisMonthIso, serverMonths)

      if (earliestUnlockedMonthEnd && !isSameMonth(day, earliestUnlockedMonthEnd)) {
        const lastWorkDay = getLastWorkDayOfMonthForEmployee(
          earliestUnlockedMonthEnd,
          contract,
          publicHolidays,
          userExpectedHours
        )
        dispatch({ type: 'CHANGE_CURRENTLY_EDITING_DAY', day: lastWorkDay, rescrollNonce: Date.now() })
      }
    }
  }

  userHasHolidayType(holidayType: 'unpaid' | 'floating' | 'quota-limited' | 'japan-flex') {
    const contractsLatestFirst = _.sortBy(this.props.data.contracts, 'start').reverse()

    const currentOrLatestContract =
      contractsLatestFirst.find((c) => c.start <= today() && (!c.finish || c.finish >= today())) ??
      contractsLatestFirst[0]

    const currentUserCountry = currentOrLatestContract?.company.country
    const userCountryUnknown = !currentUserCountry

    if (userCountryUnknown) {
      return true // Let server decide the correct answer
    }

    switch (holidayType) {
      case 'unpaid':
        return ['FI', 'NL', 'SE'].includes(currentUserCountry)
      case 'floating':
      case 'quota-limited':
        return currentUserCountry === 'US'
      case 'japan-flex':
        return currentUserCountry === 'JP'
    }
  }

  fetchData() {
    const { dispatch } = this.props
    const { loggedInUsername, dataOwnerUsername: username } = this.props.user

    if (!loggedInUsername || !username) {
      return Promise.reject('Could not fetch data, user is not defined')
    }

    const promises = [
      fetchEmployee(username)
        .then((employee) => {
          dispatch({ type: 'UPDATE_DATA_OWNER_USER', employee })
          dispatch({ type: 'UPDATE_LOCALE', language: employee.language })
          return null
        })
        .catch((e) => {
          if (e.status === 404 && loggedInUsername !== username) {
            alert(`Cannot impersonate ${username}: employee not found`)
          } else if (e.status === 403) {
            alert(`Cannot impersonate ${username}: you do not have sufficient permissions for this`)
          }
          console.error('Failed to load basic user details:', e)
        }),

      fetchExpectedHours(username)
        .then((expectedHours) => dispatch({ type: 'UPDATE_EXPECTED_HOURS', expectedHours }))
        .catch((e) => console.error('Failed to load user expected hours:', e)),

      fetchContracts(username)
        .then((contracts) => dispatch({ type: 'UPDATE_CONTRACTS', contracts }))
        .catch((e) => console.error('Failed to load contracts:', e)),

      fetchProjectCodesWithTimeRanges(username)
        .then((hourCodes) => dispatch({ type: 'UPDATE_PROJECT_CODES_WITH_TIME_RANGES', hourCodes }))
        .catch((e) => console.error('Failed to load project codes:', e))
        .then((action) => {
          if (action && action.hourCodes) {
            _.uniq(action.hourCodes.map(({ policyGroup }) => policyGroup)).forEach((policyGroup) => {
              if (policyGroup) this.fetchPolicyGroup(policyGroup)
            })
          }
          return null
        })
        .catch((e) => console.error('Failed to load policy groups:', e)),

      fetchPublicHolidays(username)
        .then((publicHolidays) => dispatch({ type: 'UPDATE_PUBLIC_HOLIDAYS', publicHolidays }))
        .catch((e) => console.error('Failed to load public holidays:', e)),

      fetchAllAbsenceCodes()
        .then((absenceCodes) => dispatch({ type: 'UPDATE_ABSENCE_CODES', absenceCodes }))
        .catch((e) => console.error('Failed to load absence codes:', e)),

      fetchEmployeeBaseDifferenceAdjustments(username)
        .then((baseDifferenceAdjustments) =>
          dispatch({ type: 'UPDATE_EMPLOYEE_BASE_DIFFERENCE_ADJUSTMENTS', baseDifferenceAdjustments })
        )
        .catch((e) => console.error("Failed to load employee's base difference adjustments:", e)),
    ]

    return Promise.all(promises)
  }

  fetchPolicyGroup(policyGroup: string) {
    const { dispatch } = this.props
    fetchPoliciesGroups(policyGroup)
      .then((groups) => dispatch({ type: 'UPDATE_POLICY_GROUPS', hourCodePolicies: groups }))
      .catch((e) => console.error('Failed to load policy group', e))
  }

  fetchCurrentHolidays() {
    const { dispatch } = this.props
    const { loggedInUsername, dataOwnerUsername: username } = this.props.user

    if (!loggedInUsername || !username) {
      return
    }

    fetchCurrentHolidays(username)
      .then((currentHolidays) => dispatch({ type: 'UPDATE_CURRENT_HOLIDAYS', currentHolidays }))
      .catch((e) => console.error('Failed to load user holidays:', e))

    if (this.userHasHolidayType('unpaid')) {
      fetchUnpaidHolidaySummary(username)
        .then((unpaidVacationSummary) => dispatch({ type: 'UPDATE_UNPAID_VACATION_SUMMARY', unpaidVacationSummary }))
        .catch((e) => console.error('Failed to load unpaid vacations:', e))
    }

    if (this.userHasHolidayType('floating')) {
      fetchFloatingHolidaySummary(username)
        .then((floatingHolidaySummary) => dispatch({ type: 'UPDATE_FLOATING_HOLIDAY_SUMMARY', floatingHolidaySummary }))
        .catch((e) => console.error('Failed to load floating holidays:', e))
    }

    if (this.userHasHolidayType('quota-limited')) {
      fetchQuotaLimitedAbsenceSummary(username)
        .then((quotaLimitedAbsenceSummary) =>
          dispatch({ type: 'UPDATE_QUOTA_LIMITED_ABSENCE_SUMMARY', quotaLimitedAbsenceSummary })
        )
        .catch((e) => console.error('Failed to load quota limited absences:', e))
    }
    if (this.userHasHolidayType('japan-flex')) {
      fetchJapanFlexHolidaySummary(username)
        .then((japanFlexHolidaySummary) => {
          return dispatch({ type: 'UPDATE_JAPAN_FLEX_HOLIDAY_SUMMARY', japanFlexHolidaySummary })
        })
        .catch((e) => console.error('Failed to load Japan flex holidays:', e))
    }
  }

  async fetchEntries() {
    const { dispatch } = this.props
    const { loggedInUsername, dataOwnerUsername: username } = this.props.user
    const { hasServerDaysBeenLoaded } = this.props.data
    const isFirstFetch = !hasServerDaysBeenLoaded

    if (!loggedInUsername || !username) {
      return console.error("Can't fetch hours entries before logged in user is fetched")
    }

    try {
      const { days, versionConflictState, lastEventId, months } = await fetchHoursEntries(username)
      dispatch({ type: 'UPDATE_SERVER_DAYS', days, months, versionConflictState })

      if (this.websocket) {
        this.websocket.close()
      }

      // the backend websocket connection only sends events
      // of the currently logged in user, so it is not currently
      // supported while impersonating
      if (loggedInUsername === username && !IS_E2E_TEST) {
        this.websocket = openWebsocket(lastEventId, this.onServerDayUpdatesReceived, this.onServerMonthUpdateReceived)
      }
    } catch (e) {
      console.error('Failed to fetch Hours entries:', e)
      this.props.dispatch({ type: 'UPDATE_CONNECTION_STATUS', isServerConnectionWorking: false })
    }

    if (isFirstFetch && this.dayListRef.current) {
      this.dayListRef.current.scrollToDay(today())
    }
  }

  modifyEntry(editContext: EditContext, updateDiff: Partial<WorkEntry>) {
    const { dispatch } = this.props

    const { day } = editContext
    const { serverDays, sentChangedLocalDays, unsentChangedLocalDays } = this.props.data
    const currentContract = this.props.data.contracts.find((c) => c.start <= day && (!c.finish || c.finish >= day))

    const dayData = {
      ...createDefaultDay(day),
      ...serverDays[day],
      ...sentChangedLocalDays[day],
      ...unsentChangedLocalDays[day],
    }

    const autofilledUpdateDiff = addAutofillsToUpdateDiff(
      editContext,
      updateDiff,
      dayData,
      currentContract,
      this.props.hourCodes,
      this.props.data.hourCodePolicies
    )
    dispatch({ type: 'UPDATE_LOCAL_ENTRY', editContext, updateDiff: autofilledUpdateDiff })

    void this.sendLocalChangesToServer() // This is a debounced function, hence the void.
  }

  keyboardShortcutListener(e: KeyboardEvent) {
    // Copy previous day entries
    if ((e.ctrlKey || e.altKey) && e.key === 'd' && this.props.copyPreviousEntry) {
      const { context, diff } = this.props.copyPreviousEntry
      this.modifyEntry(context, _.omit(diff, 'id'))
      // automatically select next day if entry just copied was the first entry for the day
      if (context.indexInDay === 0) {
        this.props.dispatch({
          type: 'CHANGE_CURRENTLY_EDITING_DAY',
          day: nextWorkingDay(context.day, this.props.data.publicHolidays),
        })
      }
      e.preventDefault()
    }

    // Jump to today
    if ((e.ctrlKey || e.altKey) && e.key === 't') {
      this.dayListRef.current?.scrollToDay(today())
      e.preventDefault()
    }

    // Open menu
    if ((e.ctrlKey || e.altKey) && e.key === 'm') {
      this.toggleMenu()
      e.preventDefault()
    }
  }

  // this function is debounced. the debounce is defined in the constructor.
  async sendLocalChangesToServer(): Promise<void> {
    const { dispatch, data, user } = this.props
    const { isPostingData, unsentChangedLocalDays, versionConflictState, totalHoursDifference } = data
    const { dataOwnerUsername: username } = user

    if (!username) return

    const changedDays = Object.entries(unsentChangedLocalDays)
    if (changedDays.length === 0 || isPostingData) {
      return
    }

    let currentVersionConflictState = versionConflictState
    let lastTotalHoursDifference = totalHoursDifference
    let updatedDays: Record<string, WorkDay> = {}
    dispatch({ type: 'BEGIN_SERVER_SEND' })
    for (const [day, dayData] of changedDays) {
      try {
        const update = await postEntries({
          username,
          day,
          entries: dayData.entries,
          versionConflictState: currentVersionConflictState,
        })
        if (update) {
          currentVersionConflictState = update.versionConflictState
          lastTotalHoursDifference = update.totalHoursDifference
          updatedDays = { ...updatedDays, [day]: update.dayData }
        }
      } catch (e) {
        if (e instanceof Response && e.status === 409) {
          await this.fetchEntries()
          document.activeElement && (document.activeElement as HTMLElement).blur()
        }

        dispatch({ type: 'SERVER_SEND_FAILED' })
        return
      }
    }

    // Hours holidays data comes from the server in aggregated form, e.g. earned: 30, used: 20. If a new holiday entry is made by the UI user,
    // the holiday counts are not updated by default for this reason. We don't want to duplicate the backend business logic in the UI,
    // so we use a fairly big hammer for this nail:
    // if there were any entries posted to the server that creates/deletes/modified an absence, re-fetch the holiday counts.
    // FIXME: provide the UI info from backend regarding which hour codes relate to holidays.
    const preUpdateDays = Object.keys(updatedDays)
      .map((d) => data.serverDays[d])
      .filter((day: WorkDay | undefined): day is WorkDay => Boolean(day)) // user might be adding a future entry, so we don't have it in serverDays
    const oneOrMoreChangeAffectedAbsences = Object.values(updatedDays)
      .concat(preUpdateDays)
      .some((d) => d.entries.some((e) => e.hourCode.startsWith('absent-') || e.hourCode.startsWith('poissa-')))
    if (oneOrMoreChangeAffectedAbsences) {
      this.fetchCurrentHolidays()
    }

    dispatch({
      type: 'SERVER_SEND_COMPLETED',
      updatedDays,
      versionConflictState: currentVersionConflictState,
      totalHoursDifference: lastTotalHoursDifference,
    })

    // recursively call this. if there are no changedDays,
    // the function will exit early in the above if-check
    this.sendLocalChangesToServer().catch(console.error)
  }

  onServerDayUpdatesReceived(
    updatedDays: PersonalHoursClientState['serverDays'],
    updatedMonthVersions: PersonalHoursClientState['versionConflictState']
  ) {
    const affectedServerDays = _.pick(this.props.data.serverDays, _.keys(updatedDays))
    const affectedMonthVersions = _.pick(this.props.data.versionConflictState, _.keys(updatedMonthVersions))
    if (!_.isEqual(affectedServerDays, updatedDays) || !_.isEqual(affectedMonthVersions, updatedMonthVersions)) {
      this.props.dispatch({
        type: 'UPDATE_SERVER_DAYS_PARTIALLY',
        updatedDays,
        updatedMonthVersions,
      })
    }
  }

  onServerMonthUpdateReceived(updatedMonth: Pick<WorkMonth, 'month' | 'locked'>) {
    const workMonth = { month: updatedMonth.month, locked: updatedMonth.locked }
    this.props.dispatch({ type: 'UPDATE_SERVER_MONTH', workMonth })
  }

  closeMenuOnEscape(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.toggleMenu()
    }
  }

  onCalendarDayClicked(day: string) {
    this.dayListRef.current?.scrollToDay(day)
    this.closePopup()
  }

  onDayListScrolled(e: React.UIEvent) {
    if (this.calendarRef.current) {
      const monthScrollProgress = findDayListFocusedMonthAndScrollProgress((e.target as HTMLElement).scrollTop)
      monthScrollProgress && this.calendarRef.current.scrollToMonth(monthScrollProgress)
    }
  }

  onWindowResized() {
    const allowSidebarRender = shouldSidebarBeVisibleForWindowSize()
    if (allowSidebarRender !== this.state.allowSidebarRender) {
      this.setState({ allowSidebarRender })
    }
  }

  toggleMenu() {
    if (this.props.settings.isMenuVisible) {
      window.removeEventListener('keydown', this.closeMenuOnEscape)
    } else {
      window.addEventListener('keydown', this.closeMenuOnEscape)
    }
    this.props.dispatch({ type: 'TOGGLE_MENU' })
  }

  closePopup() {
    this.props.settings.vacationsPopupShown &&
      this.props.dispatch({ type: 'CHANGE_VACATIONS_POPUP_SHOWN', vacationsPopupShown: false })
    this.props.settings.billingRatioPopupShown &&
      this.props.dispatch({ type: 'CHANGE_BILLING_RATIO_POPUP_SHOWN', billingRatioPopupShown: false })
    this.props.settings.summaryPopupShown &&
      this.props.dispatch({ type: 'CHANGE_SUMMARY_POPUP_SHOWN', summaryPopupShown: false })
  }

  render() {
    const { modifyEntry, toggleMenu, closePopup, onDayListScrolled, onCalendarDayClicked } = this
    const { allowSidebarRender } = this.state
    const { displayedDays } = this.props
    const {
      loggedInUsername,
      dataOwnerUsername,
      dataOwnerUser: { fullName: userFullName, startDate, isExternal },
    } = this.props.user
    const {
      totalHoursDifference,
      isPostingData,
      didLastServerUpdateFail,
      unsentChangedLocalDays,
      serverDays,
      serverMonths,
      contracts,
      publicHolidays,
      currentHolidays,
      userExpectedHours,
      unpaidVacationSummary,
      floatingHolidaySummary,
      japanFlexHolidaySummary,
      quotaLimitedAbsenceSummary,
      absenceCodes,
      baseDifferenceAdjustments,
    } = this.props.data
    const {
      locale,
      vacationsShown,
      vacationsPopupShown,
      billingRatioShown,
      billingRatioPopupShown,
      absencesShown,
      absencesPopupShown,
      summaryShown,
      summaryPopupShown,
      monthLockFeatureEnabled,
    } = this.props.settings
    const hasUnsentData = !_.isEmpty(unsentChangedLocalDays)
    const contractsLatestFirst = _.sortBy(contracts, 'start').reverse()
    const currentOrLatestContract =
      contractsLatestFirst.find((c) => c.start <= today() && (!c.finish || c.finish >= today())) ??
      contractsLatestFirst[0]

    const isReaktorEmployee = !isExternal && !!currentOrLatestContract && !!currentOrLatestContract.company
    const employeeCountry = currentOrLatestContract?.company.country
    const isReaktorFIEmployee = isReaktorEmployee && employeeCountry === 'FI'
    const isReaktorNLEmployee = isReaktorEmployee && employeeCountry === 'NL'
    const isReaktorUSEmployee = isReaktorEmployee && employeeCountry === 'US'
    const isReaktorJPEmployee = isReaktorEmployee && employeeCountry === 'JP'

    const mobilePopupShown = vacationsPopupShown || billingRatioPopupShown || absencesPopupShown || summaryPopupShown
    const desktopSidebarContentShown =
      (vacationsShown || billingRatioShown || absencesShown || summaryShown) && allowSidebarRender
    const shouldRenderSidebar = desktopSidebarContentShown || mobilePopupShown
    const shouldRenderVacation = allowSidebarRender ? vacationsShown : vacationsPopupShown
    const shouldRenderBillingRatio = allowSidebarRender ? billingRatioShown : billingRatioPopupShown

    const shouldRenderAbsences =
      isReaktorUSEmployee && allowSidebarRender && quotaLimitedAbsenceSummary ? absencesShown : absencesPopupShown
    const shouldRenderSummary = allowSidebarRender ? summaryShown : summaryPopupShown

    const daysWithErrors = displayedDays
      .map((d) => d[1])
      .map((d) => ({ date: parseISODate(d.day), hasErrors: hasValidationErrors(d) }))
      .filter((d) => d.hasErrors)
      .map((d) => d.date)

    const thisMonthIso = formatISOMonth(today())

    const recentDifferenceAdjustment = _.orderBy(baseDifferenceAdjustments, ['day', 'id'], ['desc', 'desc']).find(
      (adjustment) => isWithinInterval({ start: subMonths(today(), 1), end: today() }, adjustment.day)
    )

    return (
      <main
        className={classnames('app', `app--${environment()}`, {
          'app--impersonating': loggedInUsername !== dataOwnerUsername,
          'app--popup-open': mobilePopupShown,
        })}
      >
        <Header
          {...{
            locale,
            totalHoursDifference,
            toggleMenu,
            hasUnsentData,
            isPostingData,
            didLastServerUpdateFail,
            loggedInUsername,
            dataOwnerUsername,
            userFullName,
            daysWithErrors,
            contract: currentOrLatestContract,
            earliestUnlockedMonth: monthLockFeatureEnabled
              ? getEarliestUnlockedMonth(thisMonthIso, serverMonths)
              : undefined,
            differenceAdjustment: recentDifferenceAdjustment,
          }}
        />
        <div className='app__content-divider'>
          <DayList
            ref={this.dayListRef}
            onScroll={onDayListScrolled}
            modifyEntry={modifyEntry}
            isLockingEnabled={monthLockFeatureEnabled}
            serverDays={serverDays}
            serverMonths={serverMonths}
            daysWithErrors={daysWithErrors}
            dataOwnerUserName={dataOwnerUsername ?? loggedInUsername}
            absenceCodes={absenceCodes}
          />
          {shouldRenderSidebar && (
            <div className={classnames('app__sidebar', { 'app__sidebar--popup': mobilePopupShown })}>
              {mobilePopupShown && (
                <img src='/cross.svg' alt='Close' className={'app__sidebar-close-popup-button'} onClick={closePopup} />
              )}
              {shouldRenderAbsences && quotaLimitedAbsenceSummary && (
                <Absences locale={locale} quotaLimitedAbsenceSummary={quotaLimitedAbsenceSummary} />
              )}
              {shouldRenderSummary && (
                <Summary
                  {...{
                    calendarRef: this.calendarRef,
                    locale,
                    startDate,
                    serverDays,
                    publicHolidays,
                    daysWithErrors,
                    contracts,
                    onDayClicked: onCalendarDayClicked,
                    userExpectedHours,
                  }}
                />
              )}
              {!isExternal && shouldRenderVacation && (
                <>
                  {currentHolidays && currentOrLatestContract && (
                    <HolidayTable {...{ locale, currentHolidays, contract: currentOrLatestContract }} />
                  )}
                  {isReaktorUSEmployee && floatingHolidaySummary && (
                    <FloatingHolidaysTable
                      {...{
                        locale,
                        floatingHolidaySummary,
                      }}
                    />
                  )}
                  {(isReaktorFIEmployee || isReaktorNLEmployee) && unpaidVacationSummary && (
                    <UnpaidVacationTable
                      locale={locale}
                      unpaidVacationSummary={unpaidVacationSummary}
                      country={employeeCountry}
                    />
                  )}
                  {isReaktorJPEmployee && japanFlexHolidaySummary && (
                    <JapanFexHolidayTable
                      locale={locale}
                      japanFlexHolidaySummary={japanFlexHolidaySummary}
                      country={employeeCountry}
                    />
                  )}
                </>
              )}
              {shouldRenderBillingRatio && <BillingRatio dataOwnerUsername={dataOwnerUsername} locale={locale} />}
            </div>
          )}
        </div>
        <Menu toggleMenu={toggleMenu} absenceRenderConditionsMet={isReaktorUSEmployee} />
      </main>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    ...state,
    data: state.data.personalHoursClient,
    copyPreviousEntry: copyPreviousEntrySelector(state),
    hourCodes: hourCodesSelector(state),
    displayedDays: displayedDaysArraySelector(state),
  }
}

export default connect(mapStateToProps)(HoursClient)
