import React, { useEffect, useState } from 'react'
import { Country, CountryID, EmployeeName, NewBaseDifferenceAdjustment } from '../../lib/domain'
import adminClientStyles from '../../css/AdminClient.module.css'
import styles from './FlexiHoursManagement.module.css'
import {
  fetchCountryBaseDifferenceAdjustments,
  fetchCountryTrailingDifferences,
  fetchCurrentEmployeeContractsWithVacations,
  fetchUsersWithUnlockedHoursPerMonthForCountry,
  postBaseDifferenceAdjustment,
} from '../../lib/hoursApi'
import { FlexiHoursEditorTable } from './FlexiHoursEditorTable'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import Spinner from '../../Spinner'
import { formatISO, startOfMonth } from '../../lib/date'
import { AppDispatch } from '../../lib/store'

type FlexiHoursManagementProps = {
  defaultCountry: Country
  countries: Country[]
}

const fetchFlexihourDataForCountry = async (countryId: CountryID, dispatch: AppDispatch) => {
  try {
    const [countryUnlockedHours, employees, employeeAdjustments, trailing] = await Promise.all([
      fetchUsersWithUnlockedHoursPerMonthForCountry(countryId),
      fetchCurrentEmployeeContractsWithVacations(countryId),
      fetchCountryBaseDifferenceAdjustments(countryId),
      fetchCountryTrailingDifferences(countryId, 1),
    ])

    dispatch({ type: 'UPDATE_COUNTRY_EMPLOYEES', employeesByCountry: employees })
    dispatch({
      type: 'UPDATE_COUNTRY_BASE_DIFFERENCE_ADJUSTMENTS',
      countryBaseDifferenceAdjustments: { [countryId]: employeeAdjustments },
    })
    dispatch({
      type: 'UPDATE_USER_CURRENT_FLEXIHOURS',
      currentFlexihours: Object.fromEntries(
        trailing.differences
          .map((d) => {
            // Difference at the end of the month
            const flexihours = d.currentDifference
            return flexihours === undefined ? null : [d.employee.username, flexihours]
          })
          .filter((x): x is [string, number] => x !== null)
      ),
    })
    dispatch({
      type: 'UPDATE_USER_UNLOCKED_MONTHS_BY_COUNTRY',
      userUnlockedHoursOfCountry: countryUnlockedHours,
      country: countryId,
    })
  } catch (e) {
    console.error('Could not get users with unlocked hours')
    throw e
  }
}

const FlexiHoursManagement: React.FC<FlexiHoursManagementProps> = ({ defaultCountry, countries }) => {
  const [selectedCountry, setSelectedCountry] = useState<Country>(defaultCountry)

  const locale = useAppSelector((state) => state.settings.locale)
  const countryAdjustments = useAppSelector(
    (state) => state.data.adminClient.countryBaseDifferenceAdjustments[selectedCountry.id]
  )
  const countryEmployees = useAppSelector((state) => state.data.adminClient.employeesByCountry[selectedCountry.id])
  const userFlexihours = useAppSelector((state) => state.data.adminClient.currentFlexihours)
  const unlockedHoursOfCountry =
    useAppSelector((state) => state.data.adminClient.userUnlockedHoursByCountry).find(
      (u) => u.country === selectedCountry.id
    )?.userUnlockedHours ?? null

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (countryAdjustments) {
      return
    }

    fetchFlexihourDataForCountry(selectedCountry.id, dispatch).catch(() => {
      alert(locale.texts.admin.flexiHoursAdjustments.apiError)
    })
  }, [selectedCountry.id, countryAdjustments, dispatch])

  const flexihoursContent = (content: JSX.Element) => (
    <div className={adminClientStyles.container}>
      <h1>{locale.texts.admin.flexiHoursAdjustments.title}</h1>
      <div className={styles.countrySelectContainer}>
        <label htmlFor='country'>{locale.texts.admin.unlockedHoursStats.country}</label>
        <select
          data-test='country-select'
          id='country'
          value={selectedCountry.id}
          onChange={(e) => {
            setSelectedCountry(countries.find((c) => c.id === e.target.value) || defaultCountry)
          }}
        >
          {countries.map((country) => (
            <option key={country.id} value={country.id}>
              {country.name}
            </option>
          ))}
        </select>
        {content}
      </div>
    </div>
  )

  if (!countryAdjustments || !countryEmployees || !unlockedHoursOfCountry) {
    return flexihoursContent(<Spinner />)
  }

  const addAdjustment = async (username: string, adjustment: NewBaseDifferenceAdjustment) => {
    const monthStart = startOfMonth(new Date())
    const newAdjustment = await postBaseDifferenceAdjustment(username, formatISO(monthStart), adjustment)

    dispatch({
      type: 'ADD_COUNTRY_BASE_DIFFERENCE_ADJUSTMENT',
      country: selectedCountry.id,
      adjustment: newAdjustment,
    })
  }

  const employees = countryEmployees
    .map((employee) => employee.employee)
    .filter((e): e is EmployeeName => e !== undefined)

  const adjustmentsByEmployee = Object.fromEntries(
    countryAdjustments.map((employeeAdjustments) => [employeeAdjustments.username, employeeAdjustments.adjustments])
  )

  const countryFlexihourData = employees.map((employee) => ({
    employee,
    currentFlexiHours: userFlexihours[employee.username] ?? Number.NaN,
    adjustments: adjustmentsByEmployee[employee.username] ?? [],
    unlockedHours: unlockedHoursOfCountry[employee.username] ?? [],
  }))

  return flexihoursContent(
    <FlexiHoursEditorTable employees={countryFlexihourData} addAdjustment={addAdjustment} locale={locale} />
  )
}

export default FlexiHoursManagement
